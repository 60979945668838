<template>
  <div>
    <div class="columns">
      <div class="column">
        <h2 class="title is-3">Шаблоны</h2>
      </div>
      <div class="column">
        <v-select
          outlined
          dense
          hide-details
          clearable
          :items="types"
          item-value="value"
          item-text="text"
          label="Тип шаблона"
          v-model="params.type"
          @click:clear="onClear"
          @input="filterTemplates()"
        ></v-select>
      </div>
      <div class="column">
        <b-button
          class="is-pulled-right"
          type="is-primary"
          icon-right="plus"
          @click="createTemplate()"
        >Добавить шаблон</b-button
        >
      </div>
      <div class="column">
        <b-button
          class="is-pulled-right"
          type="is-primary"
          icon-right="plus"
          @click="updateTemplatesText()"
        >Обновить шаблоны и лендинги</b-button
        >
      </div>
    </div>

    <div v-if="!defaultTemplate" class="title is-5 mt-5 error--text">
      Нельзя удалять шаблоны, пока не назначен дефолтный
    </div>


    <!-- ТАБЛИЦА -->
    <b-table
      :data="templates.results"
      class="users-table"

      paginated
      backend-pagination
      :total="templates.total"
      :per-page="params.perPage"
      @page-change="filterTemplates"
    >
      <b-table-column label="Default?" v-slot="props">
        <b-switch @input="changeDefault(props.row)" v-model="props.row.is_default"></b-switch>
      </b-table-column>

      <b-table-column label="Имя" v-slot="props">
        <span>{{ props.row.name }}</span>
      </b-table-column>

      <b-table-column label="Действия" v-slot="props">
        <div>
          <b-tooltip label="Перейти">
            <b-button class="edit_icon" @click="redirect(props.row)">
              <svg xmlns="http://www.w3.org/2000/svg" style="enable-background:new 0 0 64 64;" viewBox="0 0 64 64" xml:space="preserve">
                        <g>
                          <g transform="translate(382.000000, 380.000000)">
                            <!-- fill="#6d757d" -->
                            <polyline points="-352.3,-343.4 -354.6,-345.7 -328.8,-371.4 -326.6,-369.2 -352.3,-343.4    "/>
                            <polyline points="-326,-354.9 -329.4,-354.9 -329.4,-368.6 -343.1,-368.6 -343.1,-372 -326,-372      -326,-354.9    "/>
                            <path d="M-334.6-324h-34.3c-2.8,0-5.1-2.3-5.1-5.1v-34.3c0-2.8,2.3-5.1,5.1-5.1h18.9v3.4h-18.9     c-0.9,0-1.7,0.8-1.7,1.7v34.3c0,0.9,0.8,1.7,1.7,1.7h34.3c0.9,0,1.7-0.8,1.7-1.7V-348h3.4v18.9C-329.4-326.3-331.7-324-334.6-324     " />
                          </g>
                        </g>
                      </svg>
            </b-button>
          </b-tooltip>

          <b-tooltip label="Обновить template" v-if="authUser.role ==='admin' || authUser.role ==='superuser'">
            <b-button class="edit_icon" @click="updateTemplate(props.row)">
              <svg viewBox="0 0 24 24" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4"></path>
                <path d="M13.5 6.5l4 4"></path>
              </svg>
            </b-button>
          </b-tooltip>

          <b-tooltip label="Обновить лендинги template" v-if="authUser.role ==='admin' || authUser.role ==='superuser'">
            <b-button class="edit_icon pa-1" @click="updateTemplateTextById(props.row)" style="color: var(--edit)">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-archive" viewBox="0 0 16 16"
                   style="stroke: none !important; margin-top: 8px; width: 1.35rem; height: 1.35rem;">
                <path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 12.5V5a1 1 0 0 1-1-1V2zm2 3v7.5A1.5 1.5 0 0 0 3.5 14h9a1.5 1.5 0 0 0 1.5-1.5V5H2zm13-3H1v2h14V2zM5 7.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/>
              </svg>
            </b-button>
          </b-tooltip>

          <b-tooltip label="Удалить">
            <b-button
              :disabled="!(authUser.role === 'admin' || authUser.role === 'superuser') || props.row.is_default || !defaultTemplate"
              class="delete_icon"
              @click="deleteTemplate(props.row)">
              <svg viewBox="0 0 24 24" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                <path d="M4 7l16 0"></path>
                <path d="M10 11l0 6"></path>
                <path d="M14 11l0 6"></path>
                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
              </svg>
            </b-button>
          </b-tooltip>
        </div>
      </b-table-column>

      <!-- Пагинация и выбор количества элементов на странице -->
      <template slot="bottom-left">
        <div class="pagination-controls">
          <v-select
            style="width: fit-content; float: left;"
            v-model="params.perPage"
            @change="filterTemplates"
            :items="perPageOptions"
            item-text="name"
            item-value="value"
            outlined
            hide-details
            dense
          />
        </div>
      </template>

    </b-table>

    <TemplateDetailsModal></TemplateDetailsModal>
    <ConfirmDeletionModal></ConfirmDeletionModal>
  </div>
</template>

<script>
import TemplateDetailsModal from '@/app/client/templates/shared/modals/templateDetailsModal.vue';
import ConfirmDeletionModal from '@/app/client/templates/shared/modals/confirmDeletionTemplateModal.vue';

export default {
  name: "TemplatesList",
  components: {
    TemplateDetailsModal,
    ConfirmDeletionModal,
  },
  data() {
    return {
      params: {
        type: '',

        // пагинация
        perPage: 20,
        page: 1
      },
      types: [
        { value: "app", text: 'Приложение' },
        { value: "web", text: 'Web' },
      ],
      perPageOptions: [
        { name: "10 на странице", value: 10 },
        { name: "20 на странице", value: 20 },
        { name: "50 на странице", value: 50 },
        { name: "100 на странице", value: 100 },
      ],
    };
  },
  created() {
    this.$store.dispatch("GET_DEFAULT_TEMPLATE");
    this.filterTemplates();
  },
  watch: {
    isOpenDetailsModal(newVal) {
      if (!newVal) {
        this.filterTemplates();
      }
    },
    isOpenConfirmDeletionModelTemplate(newVal) {
      if (!newVal) {
        this.filterTemplates();
      }
    }
  },
  computed: {
    isOpenDetailsModal: {
      get() {
        return this.$store.getters.IS_TEMPLATE_DETAILS_MODAL_OPENED;
      },
      set(value) {
        this.$store.commit("SET_TEMPLATE_DETAILS_MODAL_IS_OPENED", value);
      }
    },
    isOpenConfirmDeletionModelTemplate: {
      get() {
        return this.$store.getters.IS_CONFIRM_DELETION_TEMPLATE_MODAL_OPENED;
      },
      set(value) {
        this.$store.commit("SET_CONFIRM_DELETION_TEMPLATE_MODAL_IS_OPENED", value);
      }
    },
    defaultTemplate: {
      get() {
        return this.$store.getters.DEFAULT_TEMPLATE;
      },
      set(value) {
        this.$store.commit('SET_DEFAULT_TEMPLATE', value);
      }
    },
    isOpenDefaultTemplateModal: {
      get() {
        return this.$store.getters.IS_DELETION_DEFAULT_TEMPLATE_MODAL_OPENED;
      },
      set(value) {
        this.$store.commit("SET_DELETION_DEFAULT_TEMPLATE_MODAL", value);
      },
    },
    isOpenDontHaveDefaultModal: {
      get() {
        return this.$store.getters.IS_DONT_HAVE_DEFAULT_TEMPLATE_MODAL_OPENED;
      },
      set(value) {
        this.$store.commit("SET_DONT_HAVE_DEFAULT_TEMPLATE_MODAL", value);
      }
    },
    authUser() {
      return this.$store.getters.AUTH_USER;
    },
    templates() {
      return this.$store.getters.TEMPLATES;
    },
  },
  methods: {
    changeDefault(template) {
      let data = {
        id: template.id,
        is_default: template.is_default
      };
      this.$store.dispatch('SET_NEW_DEFAULT_VALUE', data).then(() => {
        this.$store.dispatch("GET_DEFAULT_TEMPLATE");
        this.filterTemplates();
      }).catch(() => {
        this.$toast.error('Возникла ошибка!');
      });
    },
    deleteDefaultTemplate(){
      this.isOpenDefaultTemplateModal = true;
    },
    redirect(template) {
      window.open(template.url, "_blank");
    },
    createTemplate() {
      this.isOpenDetailsModal = true;
    },
    updateTemplate(template) {
      this.$store.dispatch("CURRENT_TEMPLATE", template);
      this.isOpenDetailsModal = true;
    },
    deleteTemplate(template) {
      if (template.is_default) {
        this.deleteDefaultTemplate();
        return;
      }
      if (!this.defaultTemplate) {
        this.isOpenDontHaveDefaultModal = true;
        return;
      }
      this.$store.dispatch("CURRENT_TEMPLATE", template);
      this.$store.dispatch("CONFIRM_DELETION_TEMPLATE_MODAL", true);
    },
    onClear() {
      this.params.type = '';
      this.filterTemplates();
    },
    filterTemplates() {
      this.$store.dispatch("GET_TEMPLATES", this.params);
    },
    updateTemplatesText() {
      this.$store
        .dispatch("UPDATE_TEMPLATES")
        .then(() => {
          this.$toast.success("Лендинги обновлены!");
        })
        .catch(() => {
          this.$toast.error('Не удается обновить лендинги!');
        });
    },
    updateTemplateTextById(template) {
      this.$store
          .dispatch("UPDATE_TEMPLATES_BY_ID", template)
          .then(() => {
            this.$toast.success("Лендинги обновлены!");
          })
          .catch(() => {
            this.$toast.error('Не удается обновить лендинги!');
          });
    },
  },
};
</script>
