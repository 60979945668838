<template>
  <v-dialog
    v-model="isOpen"
    max-width="1000"
  >
    <v-card>
      <v-card-title class="headline pb-10">
        <div class="columns">
          <div class="column" v-if="currentTemplate.id == null">
            <h2 class="title is-3">Создать шаблон</h2>
          </div>
          <div class="column" v-if="currentTemplate.id != null">
            <h2 class="title is-3">Изменить шаблон: {{ currentTemplateName }}</h2>
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <v-layout row>
          <v-layout column>
            <b-field label="Название">
              <b-input v-model="currentTemplate.name"></b-input>
            </b-field>
            <b-field label="Тип шаблона: ">
              <b-select placeholder="" v-model="currentTemplate.type">
                <option
                  v-for="type in types"
                  :value="type.value"
                  :key="type.value"
                >
                  {{ type.text }}
                </option>
              </b-select>
            </b-field>
            <b-field label="URL папки с шаблоном">
              <b-input v-model="currentTemplate.offer_path" disabled></b-input>
            </b-field>
            <b-field label="Шаблон(в формате .zip)">
              <div class="columns">
                <div class="column is-6">
                  <b-field class="file is-primary" :class="{'has-name': !!currentTemplate.offer}">
                    <b-upload v-model="currentTemplate.offer" class="file-label" accept=".zip">
                      <span class="file-cta">
                          <b-icon class="file-icon" icon="upload"></b-icon>
                          <span class="file-label">Click to upload</span>
                      </span>
                      <span class="file-name" v-if="currentTemplate.offer">
                          {{ currentTemplate.offer.name }}
                      </span>
                    </b-upload>
                  </b-field>
                </div>
              </div>
            </b-field>
            <b-field label="URL изображения">
              <b-input v-model="currentTemplate.img_path" disabled></b-input>
            </b-field>
            <b-field label="Превью(скрин) шаблона">
              <div class="columns">
                <div class="column is-6">
                  <b-field class="file is-primary" :class="{'has-name': !!currentTemplate.img}">
                    <b-upload v-model="currentTemplate.img" class="file-label" accept="image/*">
                      <span class="file-cta">
                          <b-icon class="file-icon" icon="upload"></b-icon>
                          <span class="file-label">Click to upload</span>
                      </span>
                      <span class="file-name" v-if="currentTemplate.img">
                          {{ currentTemplate.img.name }}
                      </span>
                    </b-upload>
                  </b-field>
                </div>
              </div>
            </b-field>
          </v-layout>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close()">Закрыть</v-btn>
        <v-btn text color="primary" v-if="currentTemplate.id == null" @click="createTemplate()">Добавить</v-btn>
        <v-btn text color="primary" v-if="currentTemplate.id != null" @click="updateTemplate()">Обновить</v-btn>
      </v-card-actions>
      <overlay-loader :show="isLoading"></overlay-loader>
    </v-card>
  </v-dialog>
</template>

<script>
import OverlayLoader from '@/app/shared/components/app-loader/Loader';

export default {
  name: 'TemplateDetailsModal',
  components: {
    OverlayLoader,
  },
  data() {
    return {
      types: [
        { value: "app", text: 'Приложение' },
        { value: "web", text: 'Web' },
      ],

      currentTemplateName: '', // Копия имени шаблона
    };
  },
  watch: {
    isOpen(newVal) {
      if (newVal) {
        // Когда диалог открывается, создаем копию имени профиля
        this.currentTemplateName = this.currentTemplate.name;
      }
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters.IS_CURRENT_TEMPLATE_LOADING;
    },
    isOpen() {
      return this.$store.getters.IS_TEMPLATE_DETAILS_MODAL_OPENED;
    },
    currentTemplate: {
      get() {
        return this.$store.getters.GET_CURRENT_TEMPLATE;
      },
      set(value) {
        this.$store.commit("SET_CURRENT_TEMPLATE", value);
      },
    },
  },
  methods: {
    close() {
      this.$store.dispatch("CURRENT_TEMPLATE", { type: "app" });
      this.$store.dispatch('TEMPLATE_DETAILS_MODAL', false);
    },
    createTemplate(){
      this.$store.dispatch('CREATE_TEMPLATE', this.currentTemplate)
        .then(() => {
          this.$toast.success("Шаблон успешно добавлен");
          this.$store.dispatch("CURRENT_TEMPLATE", { });
          this.$store.dispatch('TEMPLATE_DETAILS_MODAL', false);
        })
        .catch(() => {
          this.$toast.error("Ошибка при добавлении шаблона");
        });
    },
    updateTemplate(){
      this.$store.dispatch('UPDATE_CURRENT_TEMPLATE', this.currentTemplate)
        .then(() => {
          this.$toast.success("Шаблон успешно изменен");
          this.$store.dispatch("CURRENT_TEMPLATE", { });
          this.$store.dispatch('TEMPLATE_DETAILS_MODAL', false);
        })
        .catch(() => {
          this.$toast.error("Ошибка при изменении шаблона");
        });
    },
  },
};
</script>
